import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './TrailerDetail.css';

function TrailerDetail() {
  const { id } = useParams();
  const [trailer, setTrailer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Fetch trailer details from Strapi and update title and meta description
    if (trailer) {
      document.title = `${trailer.Title} | Sierra Nevada Trailer Company`;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", trailer.Description || 'Check out this top-quality trailer.');
      } else {
        const meta = document.createElement('meta');
        meta.name = 'description';
        meta.content = trailer.Description || 'Check out this top-quality trailer.';
        document.getElementsByTagName('head')[0].appendChild(meta);
      }
    }
  }, [trailer]);

  const colorNameMapping = {
    Black: 'Black ALK-FP901',
    EGrey: 'Eagle Majestic Grey',
    White: 'White ALK-FP951',
    BYellow: 'Bright Yellow ALK-FP801',
    CYellow: 'Cat Yellow ALK-FP827',
    Blue: 'Ford Blue ALK-FP101',
    IGrey: 'Ice Gray ALK-FP301',
    Green: 'John Deer Green ALK-FP401',
    Orange: 'Kubota Orange ALK-FP601',
    Red: 'Case IH Red ALK-FP701',
  };

  const getColorCode = (colorName) => {
    const colorCodes = {
      Black: '#231f20',
      EGrey: '#333e46',
      White: '#ffffff',
      BYellow: '#f1d613',
      CYellow: '#e5b024',
      Blue: '#33729f',
      IGrey: '#7f7f7f',
      Green: '#3e8b41',
      Orange: '#e93925',
      Red: '#c32d2d',
    };
    return colorCodes[colorName] || '#000000'; // Default to black if no match
  };

  useEffect(() => {
    axios
      .get(
        `https://sierranevada.onrender.com/api/trailers?filters[documentId][$eq]=${encodeURIComponent(
          id
        )}&populate=*`
      )
      .then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          setTrailer(response.data.data[0]);
        } else {
          setTrailer(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching trailer:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <p>Loading trailer details...</p>;
  }

  if (!trailer) {
    return (
      <div className="trailer-detail-container">
        <p>Trailer not found.</p>
        <Link to="/" className="back-button">Back to Categories</Link>
      </div>
    );
  }

  const formatSpecificationKey = (key) => {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? trailer.Images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === trailer.Images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Predefined order for specifications
  const specificationOrder = [
    'GAWR',
    'GAWR2',
    'Coupler Height',
    'Deck_Height',
    'Main_Frame',
    'Frame',
    'Axle',
    'Cross_Members',
    'Frame',
    'Decking',
    'Floor',
    'Sides'
  ];

  const sortSpecifications = (specifications) => {
    const sortedSpecifications = Object.entries(specifications).sort((a, b) => {
      const indexA = specificationOrder.indexOf(a[0]);
      const indexB = specificationOrder.indexOf(b[0]);

      // If a key is not in the predefined order, place it at the end
      return (indexA !== -1 ? indexA : Infinity) - (indexB !== -1 ? indexB : Infinity);
    });
    return sortedSpecifications;
  };

  // Check if the trailer's category contains "aluminum"
  const isAluminumCategory = trailer.Category.toLowerCase().includes('aluminum');

  return (
    <div className="trailer-detail-container">
      <h1 className="trailer-title">{trailer.Title}</h1>
      <div className="back-button-container">
        <Link
          to={`/category/${encodeURIComponent(trailer.Category)}`}
          className="back-button"
        >
          Back to Category
        </Link>
      </div>

      {/* Image Carousel */}
      {trailer.Images && trailer.Images.length > 0 && (
        <div className="carousel-container">
          {trailer.Images.length > 1 && (
            <button className="carousel-arrow left-arrow" onClick={handlePreviousImage}>
              &#9664;
            </button>
          )}

          <img
            src={`https://sierranevada.onrender.com${trailer.Images[currentImageIndex].url}`}
            alt={trailer.Title || 'Trailer Image'} // Use the trailer title for alt text
            className="trailer-image"
          />

          {trailer.Images.length > 1 && (
            <button className="carousel-arrow right-arrow" onClick={handleNextImage}>
              &#9654;
            </button>
          )}
        </div>
      )}

      {/* Models Section - moved just below the image */}
      <h3 className="large-header">Models/Sizes (Pricing Varies Accordingly):</h3>
      <div className="models-grid">
        {trailer.Models?.map((model) => (
          <div key={model.id} className="model-card">
            <p><strong>Model Code:</strong> {model.Model_Code}</p>
            <p><strong>Size:</strong> {model.Size}</p>
            <p><strong>Curb Weight:</strong> {model.Curb_Weight}</p>
            <p><strong>GVWR:</strong> {model.GVWR}</p>
          </div>
        ))}
      </div>

      {/* Features and Options Section - moved above Specifications */}
      <div className="features-options-section">
        <div className="features-section">
          <h3>Standard Features:</h3>
          <ul className="no-bullets">
            {trailer.Features?.map((item, index) => {
              const featureText = item.children?.[0]?.text || '';
              return <li key={index}>{featureText}</li>;
            })}
          </ul>
        </div>

        <div className="options-section">
          <h3>Popular Options (Additional Cost):</h3>
          <ul className="no-bullets">
            {trailer.Options?.map((item, index) => {
              const optionText = item.children?.[0]?.text || '';
              return <li key={index}>{optionText}</li>;
            })}
          </ul>
        </div>
      </div>

      {/* Specifications Section - moved below Features and Options */}
      <h3 className="large-header">Specifications:</h3>
      {trailer.Specifications?.[0] && (
        <table className="specifications-table">
          <tbody>
            {sortSpecifications(trailer.Specifications[0]).map(([key, value]) => {
              if (key === 'id') return null;

              const formattedValue =
                typeof value === 'string'
                  ? value.replace(/\\n/g, '<br />').replace(/(?:\r\n|\r|\n)/g, '<br />')
                  : value;

              if (formattedValue == null || formattedValue === '') return null;

              return (
                <tr key={key}>
                  <th>{formatSpecificationKey(key)}</th>
                  <td dangerouslySetInnerHTML={{ __html: formattedValue }} />
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {/* Conditionally render the Colors Section */}
      {!isAluminumCategory && (
        <>
          <h3 className="large-header">Available Colors:</h3>
          <div className="colors-section">
            <h4>Primary Colors:</h4>
            <div className="colors-grid">
              {['Black', 'EGrey'].map((colorCode) => (
                <div key={colorCode} className="color-card">
                  <div className="color-swatch-rectangle" style={{ backgroundColor: getColorCode(colorCode) }}></div>
                  <p>{colorNameMapping[colorCode]}</p>
                </div>
              ))}
            </div>

            <h4>Optional Colors (Additional Cost):</h4>
            <div className="colors-grid">
              {['White', 'BYellow', 'CYellow', 'Blue', 'IGrey', 'Green', 'Orange', 'Red'].map((colorCode) => (
                <div key={colorCode} className="color-card">
                  <div className="color-swatch-rectangle" style={{ backgroundColor: getColorCode(colorCode) }}></div>
                  <p>{colorNameMapping[colorCode]}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TrailerDetail;
