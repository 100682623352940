import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './TrailersByCategory.css';
import ComingSoonImg from './images/Coming_Soon.png';

function TrailersByCategory() {
  const { categoryName } = useParams();
  const [trailers, setTrailers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isContentVisible, setIsContentVisible] = useState(false); // State to control fade-in effect

  // Predefined order lists for different major categories
  const predefinedOrders = {
    'Dump': ['5.2K Dump', '7K Dump', '7K Deckover Dump', '10K Dump', '10K Low Profile Dump', '10K Deckover Dump', '14K Dump', '14K Deckover Dump', '14K Low Profile Dump', '16K Low Profile Dump'],
    Tilt: ['3.5 Single Axle Tilt', '5K Pan Tilt', '5.2K Single Axle Tilt', '7K Pan Tilt', '7K 4" Frame Tilt Bed', '7K 5" Frame Equipment Tilt Bed', '7K 5 " Rock Crawler Tilt Bed Driver Over Fenders w/ Outriggers', '7K, 10K Equipment Tilt Bed', '10K Tilt Bed', '10K Rock Crawler Tilt Bed Driver Over Fenders w/ Outriggers', '14K Pan Tilt', '14k Split Tilt Bed with Stationary Deck', '14k Black Hawk Full Deck Tilt Bed', '14k Rock Crawler Tilt Bed Drive Over Fenders w/ Outriggers', '14k Rock Crawler Split Tilt Bed Drive Over Fenders with Outriggers'],
    'Car Hauler/Equipment': ['Flatbed Single Axle', '7K Angle Flatbed (Light Duty)', '7K Angle Fender High Rail Flatbed', '7K 4" Frame Auto Flatbed', '7K 5" Frame Equipment Flatbed', '7k 5" Frame Rock Crawler Flatbed Drive Over Fender w/ Outriggers', '10K Auto/Equipment Flatbed', '10K Rock Crawler Flatbed Drive Over Fenders with Outriggers', '14K Flatbed', '14K Rock Crawler Flatbed Drive Over Fenders w/ Outriggers'],
    Deckover: ['3.5K, 5.2K Single Axle Deckover', '7k Deckover', '10K Deckover', '14K Deckover'],
    'Utility/Sport': ['ATV Trailer', 'ATV Slide Ramp Trailer', 'Falcon Sport Single Axle', '3.5K Falcon Utility Single Axle', '3.5K Single Axle Tilt', '3.5K/5.2K Falcon Light Speed Single Axle', '3.5K/5.2K Eagle Light Speed Trailer', '3.5K/5.2K Eagle Ultra Classic Trailer', '3.5K, 5.2K Single Axle Deckover', '5.2K Single Axle Tilt', 'Eagle Sport Trailer', 'Raft Trailer', '7K Falcon Sport', '7K Falcon Tandem Axle Utility', '7K Eagle Tandem Axle Utility', '10K Eagle Tandem Axle'],
    Landscape: ['3.5K Ultra Classic Landscape', '5.2K Ultra Classic Landscape', '7K Ultra Classic Landscape', '10K Ultra Classic Landscape'],
    'Aluminum Car Hauler/Equipment': ['Flatbed Single Axle', '7K Flatbed', '10K Flatbed'],
    'Sterling Aluminum': ['Sterling Dual Ramp ATV', 'Sterling 18" Solid Side', 'Sterling 30" Solid Side', 'Sterling Fender High Utility', 'Sterling Dump']
  };

  useEffect(() => {
    // Fetch trailers based on the category
    axios
      .get(
        `https://sierranevada.onrender.com/api/trailers?filters[Category][$eq]=${encodeURIComponent(
          categoryName
        )}&populate=*`
      )
      .then((response) => {
        let fetchedTrailers = response.data.data;

        // Get the predefined order for the current category
        const predefinedOrder = predefinedOrders[categoryName] || [];

        // Sort the trailers based on the predefined order
        fetchedTrailers.sort((a, b) => {
          const titleA = predefinedOrder.indexOf(a.Title);
          const titleB = predefinedOrder.indexOf(b.Title);

          return titleA - titleB;
        });

        // Set the sorted trailers to the state
        setTrailers(fetchedTrailers);
        setLoading(false);

        // Apply fade-in effect after loading
        setTimeout(() => {
          setIsContentVisible(true);
        }, 100); // Slight delay to trigger the animation
      })
      .catch((error) => {
        console.error('Error fetching trailers:', error);
        setLoading(false);
      });
  }, [categoryName]);

  if (loading) {
    return <p>Loading trailers...</p>;
  }

  if (trailers.length === 0) {
    return (
      <div className={`trailers-container ${isContentVisible ? 'fade-in' : ''}`}>
        <h1 className="category-title">Trailers in Category: {categoryName}</h1>
        <p>No trailers found in this category.</p>
        <Link to="/" className="back-button">
          Back to Categories
        </Link>
      </div>
    );
  }

  return (
    <div className={`trailers-container ${isContentVisible ? 'fade-in' : ''}`}>
      <h1 className="category-title">{categoryName} Trailers</h1>
      <div className="back-button-container">
        <Link to="/browse-trailers" className="back-button">
          Back to Categories
        </Link>
      </div>
      <div className="trailers-grid">
        {trailers.map((trailer) => {
          const titleLength = trailer.Title.length;

          const titleClass = titleLength > 30 ? 'very-long-title' : titleLength > 20 ? 'long-title' : '';

          // Get the image URL
          const imageUrl =
            trailer.Images && trailer.Images.length > 0
              ? `https://sierranevada.onrender.com${trailer.Images[0].url}`
              : ComingSoonImg;

          return (
            <Link
              to={`/trailer/${encodeURIComponent(trailer.documentId)}`}
              className={`card ${titleClass}`}
              key={trailer.documentId}
            >
              <div className="card-image-container">
                <img
                  src={imageUrl}
                  alt={trailer.Title || 'Trailer Image'} // Use trailer title as alt text
                  className="card-image"
                />
              </div>
              <div className="card-title">
                <span>{trailer.Title}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default TrailersByCategory;
