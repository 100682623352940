import React, { useEffect, useState } from 'react';
import './Home.css';
import logo from './images/Circular_Logo.png';
import backgroundImage from './images/trailer-background.jpg'; // Import your single background image

function Home() {

  useEffect(() => {
    document.title = 'Welcome to Sierra Nevada Trailer Company';
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", "Welcome to Sierra Nevada Trailer Company. Find the best trailers for all your needs.");
    } else {
      const meta = document.createElement('meta');
      meta.name = 'description';
      meta.content = "Welcome to Sierra Nevada Trailer Company. Find the best trailers for all your needs.";
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }, []);

  const [isContentVisible, setIsContentVisible] = useState(true);

  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <div
      className="home-container"
      style={{ backgroundImage: `url(${backgroundImage})` }} // Apply the background image directly here
    >
      <div className={`overlay ${isContentVisible ? '' : 'hidden'}`}>
        <img src={logo} alt="Company Logo" className="home-logo" />
        <h1 className="welcome-text">Welcome to Sierra Nevada Trailer Company</h1>
        <nav className="home-nav">
          <ul>
            <li><a href="/browse-trailers">Explore Our Trailers</a></li>
            <li><a href="/contact-us">Contact Us</a></li>
          </ul>
        </nav>
      </div>

      <button className="toggle-arrow" onClick={toggleContentVisibility}>
        {isContentVisible ? '⌄' : '⌃'}
      </button>
    </div>
  );
}

export default Home;
