// src/App.js

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

// Import your components
import Home from './Home';
import BrowseTrailers from './BrowseTrailers';
import TrailersByCategory from './TrailersByCategory';
import TrailerDetail from './TrailerDetail';
import ContactUs from './ContactUs';

// Helper component to conditionally render the header
function AppContent() {
  const location = useLocation();

  return (
    <div className="App">
      {/* Conditionally render the Header based on the current route */}
      {location.pathname !== '/' && <Header />}
      
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/browse-trailers" element={<BrowseTrailers />} />
          <Route path="/category/:categoryName" element={<TrailersByCategory />} />
          <Route path="/trailer/:id" element={<TrailerDetail />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
