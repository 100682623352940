// src/ContactUs.js

import React from 'react';
import './ContactUs.css';

function ContactUs() {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      
      {/* Contact Info */}
      <div className="contact-details">
        <p><strong>Main Phone:</strong> (503) 467-1494</p>
        <p><strong>Secondary Phone:</strong> (209) 602-8887</p>
        <p><strong>Email:</strong> <a href="mailto:sierranevadatrailercompany@gmail.com">sierranevadatrailercompany@gmail.com</a></p>
      </div>

      {/* Hours Section */}
      <div className="hours-section">
        <h2>Business Hours</h2>
        <p><strong>Monday and Tuesday:</strong> Hours May Vary, Please Call Ahead</p>
        <p><strong>Wednesday - Saturday:</strong> 9:00 AM - 5:00 PM</p>
        <p><strong>Sunday:</strong> Closed</p>
      </div>

      {/* Address Section */}
      <div className="address-section">
        <h2>Our Address</h2>
        <p>1022 Shaw Road, Stockton, CA 95215</p>
      </div>

      {/* Map */}
      <div className="map-container">
        <iframe
          title="Our Location"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1322.3654292129343!2d-121.24434671445766!3d37.974031092795975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1729225615832!5m2!1sen!2sus"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactUs;
