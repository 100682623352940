import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './BrowseTrailers.css';

// Import images
import dumpTrailerImg from './images/dump-category.png';
import tiltTrailerImg from './images/tilt-category.png';
import haulerTrailerImg from './images/hauler-category.png';
import deckoverTrailerImg from './images/deckover-category.png';
import utilityTrailerImg from './images/utility-category.png';
import landscapeTrailerImg from './images/landscape-category.png';
import atiltTrailerImg from './images/atilt-category.png';
import ahaulerTrailerImg from './images/ahauler-category.png';
import adeckoverTrailerImg from './images/adeckover-category.png';
import autilityTrailerImg from './images/autility-category.png';
import sterlingTrailerImg from './images/sterling-category.png';

const steelCategories = [
  { name: 'Dump', image: dumpTrailerImg },
  { name: 'Tilt', image: tiltTrailerImg },
  { name: 'Car Hauler/Equipment', image: haulerTrailerImg },
  { name: 'Deckover', image: deckoverTrailerImg },
  { name: 'Utility/Sport', image: utilityTrailerImg },
  { name: 'Landscape', image: landscapeTrailerImg },
];

const aluminumCategories = [
  { name: 'Aluminum Tilt', image: atiltTrailerImg },
  { name: 'Aluminum Car Hauler/Equipment', image: ahaulerTrailerImg },
  { name: 'Aluminum Deckover', image: adeckoverTrailerImg },
  { name: 'Aluminum Utility/Sport', image: autilityTrailerImg },
  { name: 'Sterling Aluminum', image: sterlingTrailerImg },
];

function BrowseTrailers() {
  const [fadeInClass, setFadeInClass] = useState('');

  useEffect(() => {
    document.title = 'Browse Trailers | Sierra Nevada Trailer Company';
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", "Explore Sierra Nevada Trailer Company's wide range of trailers including dump, tilt, equipment, and utility trailers. Find your perfect trailer today!");
    } else {
      const meta = document.createElement('meta');
      meta.name = 'description';
      meta.content = "Explore Sierra Nevada Trailer Company's wide range of trailers including dump, tilt, equipment, and utility trailers. Find your perfect trailer today!";
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }, []);

  // Trigger the fade-in effect after the component mounts
  useEffect(() => {
    setFadeInClass('fade-in');
  }, []);

  return (
    <div className={`browse-trailers-container ${fadeInClass}`}>
      <h1 className="page-title">Browse Sierra Nevada Trailer Categories</h1>
      <div className="categories-wrapper">
        {/* Steel Categories */}
        <div className="categories-section">
          <h2 className="section-title">Steel</h2>
          <div className="categories-grid">
            {steelCategories.map((category, index) => (
              <Link
                to={`/category/${encodeURIComponent(category.name)}`}
                className="card"
                key={index}
              >
                <div className="card-image-container">
                  <img
                    src={category.image}
                    alt={`${category.name} Trailer`}
                    className="card-image"
                  />
                </div>
                <div className="card-title">
                  <span>{category.name}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {/* Aluminum Categories */}
        <div className="categories-section">
          <h2 className="section-title">Aluminum</h2>
          <div className="categories-grid">
            {aluminumCategories.map((category, index) => (
              <Link
                to={`/category/${encodeURIComponent(category.name)}`}
                className="card"
                key={index}
              >
                <div className="card-image-container">
                <img
                  src={category.image}
                  alt={`${category.name} Trailer Category`} // Use category name for alt text
                  className="card-image"
                />
                </div>
                <div className="card-title">
                  <span>{category.name}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrowseTrailers;
